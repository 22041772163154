import random from 'lodash.random';
import samplesize from 'lodash.samplesize';
import sample from 'lodash.sample';

class Question {
  constructor(data) {
    Object.assign(this, data);
  }
}

const getAnswerForQuestion = (questionType, allPeople) => {
  let possibleAnswers = allPeople;
  let others;
  let answer;

  if (questionType === "firstName") {
    answer = sample(possibleAnswers);
    others = samplesize(allPeople.filter((p) => (p.firstName !== answer.firstName) && (p.lastName !== answer.lastName)), 2);
  } else if (questionType === "parentOf") {
    possibleAnswers = allPeople.filter((p) => {
      return p[questionType] !== undefined
    });
    answer = sample(possibleAnswers);
    const withoutAnswer = allPeople.filter((p) => (p.firstName !== answer.firstName) && (p.lastName !== answer.lastName));
    others = samplesize(withoutAnswer.filter((p) => !p.childOf || !p.childOf.includes(`${answer.firstName} ${answer.lastName}`)), 2);
  } else if (questionType === "childOf") {
    possibleAnswers = allPeople.filter((p) => {
      return p[questionType] !== undefined
    });
    answer = sample(possibleAnswers);
    const withoutAnswer = allPeople.filter((p) => (p.firstName !== answer.firstName) && (p.lastName !== answer.lastName));
    others = samplesize(withoutAnswer.filter((p) => !p.parentOf || !p.parentOf.includes(`${answer.firstName} ${answer.lastName}`)), 2);
  } else if (questionType === "spouseOf") {
    possibleAnswers = allPeople.filter((p) => {
      return p[questionType] !== undefined
    });
    answer = sample(possibleAnswers);
    const withoutAnswer = allPeople.filter((p) => (p.firstName !== answer.firstName) && (p.lastName !== answer.lastName));
    others = samplesize(withoutAnswer.filter((p) => !p.spouseOf || !p.spouseOf.includes(`${answer.firstName} ${answer.lastName}`)), 2);
  }

  let people = [answer, ...others];
  let randomShift = random(2);

  for (let i=0; i<=randomShift; i++) {
    people.push(people.shift());
  }

  return {
    person: answer,
    people
  }
}

const generateQuestions = (allPeople) => {
  const allTypes = "firstName firstName firstName spouseOf spouseOf spouseOf parentOf parentOf parentOf childOf childOf childOf".split(" ");

  const questionTypes = samplesize(allTypes, 5);

  let questions = questionTypes.map(qt => {
    let q = new Question({
      ...getAnswerForQuestion(qt, allPeople)
    });
    q.questionText = getQuestionText(qt, q.person);
    return q;
  });

  return questions;
}

const getQuestionText = (questionType, person) => {
  const name = `${person.firstName} ${person.lastName}`;

  switch(questionType) {
    case 'firstName': 
      return `Who is ${name}?`; 

    case 'parentOf': 
      return `Who is the parent of ${sample(person.parentOf)}?`;

    case 'spouseOf': return `Who is married to ${person.spouseOf}?`;

    case 'childOf': 
      return `Who is the child of ${sample(person.childOf)}?`;

    default : 
      return `Who is ${name}?`; 
  }
}

export {
  generateQuestions
}

