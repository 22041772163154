const people = [
  {
    "firstName": "Dr. Louis",
    "lastName": "Olore",
    "parentOf": ["Meg Shepard", "Donna Militello", "Doreen Kelly", "Jeanne Moye", "Monica Anderson", "Kathy Goumas", "Paul Olore", "Louis Olore Jr.", "Stephen Olore"],
    "spouseOf": "Peggi Olore",
    "image": "./images/dr-louis-olore.png"
  },
  {
    "firstName": "Peggi",
    "lastName": "Olore",
    "parentOf": ["Meg Shehard", "Donna Militello", "Doreen Kelly", "Jeanne Moye", "Monica Anderson", "Kathy Goumas", "Paul Olore", "Louis Olore Jr.", "Stephen Olore"],
    "spouseOf": "Dr. Louis Olore",
    "image": "./images/peggi-olore.png"
  },
  {
    "firstName": "Brian",
    "lastName": "Olore",
    "childOf": ["Paul Olore", "Jane Olore"],
    "parentOf": ["Grace Olore", "Joseph Olore"],
    "spouseOf": "Dana Olore",
    "image": "./images/brian-olore.png"
  },
  {
    "firstName": "Dana",
    "lastName": "Olore",
    "parentOf": ["Grace Olore", "Joseph Olore"],
    "spouseOf": "Brian Olore",
    "image": "./images/dana-olore.png"
  },
  {
    "firstName": "Grace",
    "lastName": "Olore",
    "childOf": ["Brian Olore", "Dana Olore"],
    "image": "./images/grace-olore.png"
  },
  {
    "firstName": "Joseph",
    "lastName": "Olore",
    "childOf": ["Brian Olore", "Dana Olore"],
    "image": "./images/joseph-olore.png"
  },
  {
    "firstName": "Paul",
    "lastName": "Olore Sr.",
    "childOf": ["Dr. Louis Olore", "Peggi Olore"],
    "parentOf": ["Brian Olore", "Kevin Olore", "Paul Olore Jr."],
    "spouseOf": "Jane Olore",
    "image": "./images/paul-olore-sr.png"
  },
  {
    "firstName": "Jane",
    "lastName": "Olore",
    "parentOf": ["Brian Olore", "Kevin Olore", "Paul Olore Jr."],
    "spouseOf": "Paul Olore Sr.",
    "image": "./images/jane-olore.png"
  },
  {
    "firstName": "Kevin",
    "lastName": "Olore",
    "parentOf": ["Lucy Olore", "Elly Olore"],
    "spouseOf": "Molly Olore",
    "childOf": ["Paul Olore Sr.", "Jane Olore"],
    "image": "./images/kevin-olore.png"
  },
  {
    "firstName": "Molly",
    "lastName": "Olore",
    "parentOf": ["Lucy Olore", "Elly Olore"],
    "spouseOf": "Kevin Olore",
    "image": "./images/molly-olore.png"
  },
  {
    "firstName": "Lucy",
    "lastName": "Olore",
    "childOf": ["Kevin Olore", "Molly Olore"],
    "image": "./images/lucy-olore.png"
  },
  {
    "firstName": "Elly",
    "lastName": "Olore",
    "childOf": ["Kevin Olore", "Molly Olore"],
    "image": "./images/elly-olore.png"
  },
  {
    "firstName": "Paul",
    "lastName": "Olore Jr",
    "parentOf": ["Brook Olore", "Callie Olore", "Brynn Olore"],
    "spouseOf": "Kerri Olore",
    "childOf": ["Paul Olore Sr.", "Jane Olore"],
    "image": "./images/paul-olore-jr.png"
  },
  {
    "firstName": "Kerri",
    "lastName": "Olore",
    "parentOf": ["Brook Olore", "Callie Olore", "Brynn Olore"],
    "spouseOf": "Paul Olore Jr.",
    "image": "./images/kerri-olore.png"
  },
  {
    "firstName": "Brooke",
    "lastName": "Olore",
    "childOf": ["Paul Olore Jr.","Kerri Olore"],
    "image": "./images/brooke-olore.png"
  },
  {
    "firstName": "Callie",
    "lastName": "Olore",
    "childOf": ["Paul Olore Jr.","Kerri Olore"],
    "image": "./images/callie-olore.png"
  },
  {
    "firstName": "Brynn",
    "lastName": "Olore",
    "childOf": ["Paul Olore Jr.","Kerri Olore"],
    "image": "./images/brynn-olore.png"
  },
  {
    "firstName": "Meg",
    "lastName": "Shepard",
    "childOf": ["Dr. Louis Olore","Peggi Olore"],
    "image": "./images/meg-shepard.png"
  },
  {
    "firstName": "Danny",
    "lastName": "Shepard",
    "childOf": ["Dr. Louis Olore","Peggi Olore"],
    "image": "./images/danny-shepard.png"
  },
  {
    "firstName": "Nicole",
    "lastName": "Keller",
    "parentOf": ["Garhett Keller"],
    "spouseOf": "Kevin Keller",
    "childOf": ["Meg Shepard", "Danny Shepard"],
    "image": "./images/nicole-keller.png"
  },
  {
    "firstName": "Kevin",
    "lastName": "Keller",
    "parentOf": ["Garhett Keller"],
    "spouseOf": "Nicole Keller",
    "image": "./images/kevin-keller.png"
  },
  {
    "firstName": "Garhett",
    "lastName": "Keller",
    "childOf": ["Nicole Keller","Kevin Keller"],
    "image": "./images/garhett-keller.png"
  },
  {
    "firstName": "Erica",
    "lastName": "Ames",
    "spouseOf": ["Dan Ames"],
    "childOf": ["Meg Shepard", "Danny Shepard"],
    "parentOf": ["Evelyn Ames", "Edison Ames"],
    "image": "./images/erica-ames.png"
  },
  {
    "firstName": "Dan",
    "lastName": "Ames",
    "spouseOf": "Erica Ames",
    "parentOf": ["Evelyn Ames", "Edison Ames"],
    "image": "./images/dan-ames.png"
  },
  {
    "firstName": "Evelyn",
    "lastName": "Ames",
    "childOf": ["Erica Ames", "Dan Ames"],
    "image": "./images/evelyn-ames.png"
  },
  {
    "firstName": "Edison",
    "lastName": "Ames",
    "childOf": ["Erica Ames", "Dan Ames"],
    "image": "./images/edison-ames.png"
  },
  {
    "firstName": "Louis",
    "lastName": "Olore Jr.",
    "childOf": ["Dr. Louis Olore", "Peggi Olore"],
    "parentOf": ["Amanda Olore", "Caroline Olore", "Emily Stallworth"],
    "spouseOf": "Joyce Olore",
    "image": "./images/louis-olore-jr.png"
  },
  {
    "firstName": "Joyce",
    "lastName": "Olore",
    "parentOf": ["Amanda Olore", "Caroline Olore", "Emily Stallworth"],
    "spouseOf": "Louis Olore Jr.",
    "image": "./images/joyce-olore.png"
  },
  {
    "firstName": "Amanda",
    "lastName": "Olore",
    "childOf": ["Louis Olore Jr.", "Joyce Olore"],
    "image": "./images/amanda-olore.png"
  },
  {
    "firstName": "Caroline",
    "lastName": "Olore",
    "spuseOf": "Kyle Hunter",
    "childOf": ["Louis Olore Jr.", "Joyce Olore"],
    "image": "./images/caroline-olore.png"
  },
  {
    "firstName": "Kyle",
    "lastName": "Hunter",
    "spuseOf": "Caroline Olore",
    "image": "./images/kyle-hunter.png"
  },
  {
    "firstName": "Emily",
    "lastName": "Stallworth",
    "spouseOf": "Brandon Stallworth",
    "childOf": ["Louis Olore Jr.", "Joyce Olore"],
    "image": "./images/emily-stallworth.png"
  },
  {
    "firstName": "Brandon",
    "lastName": "Stallworth",
    "spouseOf": "Emily Stallworth",
    "image": "./images/brandon-stallworth.png"
  },
  {
    "firstName": "Doreen",
    "lastName": "Kelly",
    "spouseOf": "Mike Kelly",
    "childOf": ["Dr. Louis Olore", "Peggi Olore"],
    "image": "./images/doreen-kelly.png"
  },
  {
    "firstName": "Mike",
    "lastName": "Kelly",
    "spouseOf": "Doreen Kelly",
    "image": "./images/mike-kelly.png"
  },
  {
    "firstName": "Donna",
    "lastName": "Militello",
    "spouseOf": "Rich Militello",
    "childOf": ["Dr. Louis Olore", "Peggi Olore"],
    "parentOf": ["Laura Militello", "Sara Militello", "Michael Militello"],
    "image": "./images/donna-militello.png"
  },
  {
    "firstName": "Rich",
    "lastName": "Militello",
    "spouseOf": "Donna Militello",
    "parentOf": ["Laura Militello", "Sara Militello", "Michael Militello"],
    "image": "./images/rich-militello.png"
  },
  {
    "firstName": "Laura",
    "lastName": "Militello",
    "childOf": ["Donna Militello", "Rich Militello"],
    "image": "./images/laura-militello.png"
  },
  {
    "firstName": "Sara",
    "lastName": "Militello",
    "childOf": ["Donna Militello", "Rich Militello"],
    "image": "./images/sara-militello.png"
  },
  {
    "firstName": "Michael",
    "lastName": "Militello",
    "spouseOf": "Erica Militello",
    "childOf": ["Donna Militello", "Rich Militello"],
    "image": "./images/michael-militello.png"
  },
  {
    "firstName": "Erica",
    "lastName": "Militello",
    "spouseOf": "Michael Militello",
    "parentOf": ["Grady Militello", "Luey Militello"],
    "image": "./images/erica-militello.png"
  },
  {
    "firstName": "Grady",
    "lastName": "Militello",
    "childOf": ["Michael Militello","Erica Militello"],
    "image": "./images/grady-militello.png"
  },
  {
    "firstName": "Luey",
    "lastName": "Militello",
    "childOf": ["Michael Militello","Erica Militello"],
    "image": "./images/luey-militello.png"
  },
  {
    "firstName": "Monica",
    "lastName": "Anderson",
    "spouseOf": "Corky Anderson",
    "childOf": ["Dr. Louis Olore", "Peggi Olore"],
    "parentOf": ["Molly Anderson", "Everett Anderson"],
    "image": "./images/monica-anderson.png"
  },
  {
    "firstName": "Corky",
    "lastName": "Anderson",
    "spouseOf": "Monica Anderson",
    "parentOf": ["Molly Anderson", "Everett Anderson"],
    "image": "./images/corky-anderson.png"
  },
  {
    "firstName": "Molly",
    "lastName": "Anderson",
    "childOf": ["Monica Anderson","Corky Anderson"],
    "image": "./images/molly-anderson.png"
  },
  {
    "firstName": "Everett",
    "lastName": "Anderson",
    "spouseOf": "Katherine Anderson",
    "childOf": ["Monica Anderson","Corky Anderson"],
    "image": "./images/everett-anderson.png"
  },
  {
    "firstName": "Katherine",
    "lastName": "Anderson",
    "spouseOf": "Everett Anderson",
    "image": "./images/katherine-anderson.png"
  },
  {
    "firstName": "Jeanne",
    "lastName": "Moye",
    "spouseOf": "Jimmy Moye",
    "childOf": ["Dr. Louis Olore", "Peggi Olore"],
    "parentOf": ["Patrick Moye", "Greg Moye", "Lucy Moye"],
    "image": "./images/jeanne-moye.png"
  },
  {
    "firstName": "Jimmy",
    "lastName": "Moye",
    "spouseOf": "Jeanne Moye",
    "parentOf": ["Patrick Moye", "Greg Moye", "Lucy Moye"],
    "image": "./images/jimmy-moye.png"
  },
  {
    "firstName": "Patrick",
    "lastName": "Moye",
    "spouseOf": "Erin Moye",
    "childOf": ["Jeanne Moye", "Jimmy Moye"],
    "parentOf": ["William Moye"],
    "image": "./images/patrick-moye.png"
  },
  {
    "firstName": "Erin",
    "lastName": "Moye",
    "spouseOf": "Patrick Moye",
    "parentOf": ["William Moye"],
    "image": "./images/erin-moye.png"
  },
  {
    "firstName": "William",
    "lastName": "Moye",
    "childOf": ["Patrick Moye", "Erin Moye"],
    "image": "./images/william-moye.png"
  },
  {
    "firstName": "Greg",
    "lastName": "Moye",
    "spouseOf": "Anne Marie Moye",
    "childOf": ["Jeanne Moye", "Jimmy Moye"],
    "parentOf": ["Rosemary Moye", "Maeve Moye"],
    "image": "./images/greg-moye.png"
  },
  {
    "firstName": "Anne Marie",
    "lastName": "Moye",
    "spouseOf": "Greg Moye",
    "parentOf": ["Rosemary Moye", "Maeve Moye"],
    "image": "./images/anne-marie-moye.png"
  },
  {
    "firstName": "Maeve",
    "lastName": "Moye",
    "childOf": ["Greg Moye","Anne Marie Moye"],
    "image": "./images/maeve-moye.jpg"
  },
  {
    "firstName": "Rosemary",
    "lastName": "Moye",
    "childOf": ["Greg Moye","Anne Marie Moye"],
    "image": "./images/rosemary-moye.png"
  },
  {
    "firstName": "Lucy",
    "lastName": "White",
    "childOf": ["Jeanne Moye", "Jimmy Moye"],
    "spouseOf": "Griffin White",
    "image": "./images/lucy-white.png"
  },
  {
    "firstName": "Griffin",
    "lastName": "White",
    "spouseOf": "Lucy White",
    "image": "./images/griffin-white.png"
  },
  {
    "firstName": "Kathy",
    "lastName": "Goumas",
    "spouseOf": "Bill Goumas",
    "childOf": ["Dr. Louis Olore", "Peggi Olore"],
    "parentOf": ["Andrew Goumas", "Peter Goumas", "William Goumas", "Bailey Goumas"],
    "image": "./images/kathy-goumas.png"
  },
  {
    "firstName": "Bill",
    "lastName": "Goumas",
    "spouseOf": "Kathy Goumas",
    "parentOf": ["Andrew Goumas", "Peter Goumas", "William Goumas", "Bailey Goumas"],
    "image": "./images/bill-goumas.png"
  },
  {
    "firstName": "Andrew",
    "lastName": "Goumas",
    "childOf": ["Kathy Goumas", "Bill Goumas"],
    "image": "./images/andrew-goumas.png"
  },
  {
    "firstName": "Peter",
    "lastName": "Goumas",
    "childOf": ["Kathy Goumas", "Bill Goumas"],
    "image": "./images/peter-goumas.png"
  },
  {
    "firstName": "William",
    "lastName": "Goumas",
    "childOf": ["Kathy Goumas", "Bill Goumas"],
    "image": "./images/william-goumas.png"
  },
  {
    "firstName": "Bailey",
    "lastName": "Goumas",
    "childOf": ["Kathy Goumas", "Bill Goumas"],
    "image": "./images/bailey-goumas.png"
  },
  {
    "firstName": "Stephen",
    "lastName": "Olore",
    "childOf": ["Dr. Louis Olore","Peggi Olore"],
    "parentOf": ["Sam Olore", "Maggie Olore", "Anna Olore", "Jack Olore", "Quinn Olore"],
    "spouseOf": "Laurie Olore",
    "image": "./images/stephen-olore.png"
  },
  {
    "firstName": "Laurie",
    "lastName": "Olore",
    "spouseOf": "Stephen Olore",
    "parentOf": ["Sam Olore", "Maggie Olore", "Anna Olore", "Jack Olore", "Quinn Olore"],
    "image": "./images/laurie-olore.png"
  },
  {
    "firstName": "Sam",
    "lastName": "Olore",
    "childOf": ["Stephen Olore", "Laurie Olore"],
    "image": "./images/sam-olore.png"
  },
  {
    "firstName": "Maggie",
    "lastName": "Olore",
    "childOf": ["Stephen Olore", "Laurie Olore"],
    "image": "./images/maggie-olore.png"
  },
  {
    "firstName": "Jack",
    "lastName": "Olore",
    "childOf": ["Stephen Olore", "Laurie Olore"],
    "image": "./images/jack-olore.png"
  },
  {
    "firstName": "Anna",
    "lastName": "Olore",
    "childOf": ["Stephen Olore", "Laurie Olore"],
    "image": "./images/anna-olore.png"
  },
  {
    "firstName": "Quinn",
    "lastName": "Olore",
    "childOf": ["Stephen Olore", "Laurie Olore"],
    "image": "./images/quinn-olore.png"
  }
];

export default people;